<!-- 客户详情 -->
<template>
  <iframe :src="src" class="frame" />
</template>
<script>
import { getToken } from '@/utils/auth'
import { getOpenWebUrl } from '@/api/login'
export default {
  name: 'ClientDetail',
  data() {
    return {
      src: '',
      viewConfig: ''
    }
  },
  mounted: function () {
    this.findOpenWebUrl()
    window.addEventListener('message', ({ data }) => {
      if (data && data.src === 'goAllOrder') {
        this.$router.push('/orderHandle/wgzOrderSummary')
      } else if (data && data.src === 'goLegal') {
        //关闭当前页 跳转上一页
        // const route = this.$route
        // if (route.path.indexOf('clientCenter/detail') > -1) {
        //   this.$store.dispatch('tagsView/delView', route).then(() => {
        //     const src = '/clientCenter/legalService' + (data.id ? `?id=${data.id}` : '')
        //     this.$router.push(src)
        //   })
        // }
        this.$router.push('/clientCenter/clientCenter/index')
      } else if(data && data.src === 'goClient') {
        //关闭当前页 跳转上一页
        // const route = this.$route
        // if (route.path.indexOf('clientCenter/detail') > -1) {
        //   this.$store.dispatch('tagsView/delView', route).then(() => {
        //     this.$router.push('/clientCenter/clientList')
        //   })
        // }
        this.$router.push('/clientCenter/clientCenter/index')
      } else if (data && data.src === 'goAccount') {
        this.$router.push('/clientCenter/account?id=' + data.id)
      }
    })
  },
  methods: {
    findOpenWebUrl() {
      getOpenWebUrl().then((res) => {
        this.viewConfig = res.data
        let src = this.viewConfig + '/clientCenter/clientDetail?token=' + getToken()
        const id = this.$route.query.id
        if (id) {
          src += '&id=' + id
        }
        this.src = src
      })
    }
  }
}
</script>
<style scoped></style>
